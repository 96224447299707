(function($) {
  $.extend($.summernote.lang, {
    'cs-CZ': {
      font: {
        bold: 'Tučné',
        italic: 'Kurzíva',
        underline: 'Podtržené',
        clear: 'Odstranit styl písma',
        height: 'Výška řádku',
        strikethrough: 'Přeškrtnuté',
        size: 'Velikost písma'
      },
      image: {
        image: 'Obrázek',
        insert: 'Vložit obrázek',
        resizeFull: 'Původní velikost',
        resizeHalf: 'Poloviční velikost',
        resizeQuarter: 'Čtvrteční velikost',
        floatLeft: 'Umístit doleva',
        floatRight: 'Umístit doprava',
        floatNone: 'Neobtékat textem',
        shapeRounded: 'Shape: Rounded',
        shapeCircle: 'Shape: Circle',
        shapeThumbnail: 'Shape: Thumbnail',
        shapeNone: 'Shape: None',
        dragImageHere: 'Přetáhnout sem obrázek',
        dropImage: 'Drop image or Text',
        selectFromFiles: 'vybrat soubor...',
        url: 'Vložit obrázek  přes jeho URL',
        remove: 'Odstranit',
        original: 'Original',
        fromFtp: 'z FTP',
        wrapWithLink: 'URL odkazu',
        editLink: 'Upravit odkaz',
        selectFromDesktop: "Vybrat soubor z mého počítače"
      },
      video: {
        video: 'Video',
        videoLink: 'Odkaz videa',
        insert: 'Vložit video',
        url: 'URL videa?',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion nebo Youku)'
      },
      link: {
        link: 'Odkaz',
        insert: 'Vytvořit odkaz',
        unlink: 'Zrušit odkaz',
        edit: 'Upravit',
        textToDisplay: 'Zobrazovaný text',
        url: 'Na jaké URL má tento odkaz vést?',
        openInNewWindow: 'Otevřít v novém okně'
      },
      table: {
        table: 'Tabulka',
        addRowAbove: 'Vložit řádek nad',
        addRowBelow: 'Vložit řádek pod',
        addColLeft: 'Vložit sloupec nalevo',
        addColRight: 'Vložit sloupec napravo',
        delRow: 'Smazat řádek',
        delCol: 'Smazat sloupec',
        delTable: 'Smazat tabulku',
        switchBorder: 'Zap./vyp. ohraničení'
      },
      hr: {
        insert: 'Vložit vodorovnou čáru'
      },
      style: {
        style: 'Styl',
        p: 'Normální',
        blockquote: 'Citace',
        pre: 'Kód',
        h1: 'Nadpis 1',
        h2: 'Nadpis 2',
        h3: 'Nadpis 3',
        h4: 'Nadpis 4',
        h5: 'Nadpis 5',
        h6: 'Nadpis 6'
      },
      lists: {
        unordered: 'Odrážkový seznam',
        ordered: 'Číselný seznam'
      },
      options: {
        help: 'Nápověda',
        fullscreen: 'Celá obrazovka',
        codeview: 'HTML kód'
      },
      paragraph: {
        paragraph: 'Odstavec',
        outdent: 'Zvětšit odsazení',
        indent: 'Zmenšit odsazení',
        left: 'Zarovnat doleva',
        center: 'Zarovnat na střed',
        right: 'Zarovnat doprava',
        justify: 'Zarovnat oboustranně'
      },
      color: {
        recent: 'Aktuální barva',
        more: 'Další barvy',
        background: 'Barva pozadí',
        foreground: 'Barva písma',
        transparent: 'Průhlednost',
        setTransparent: 'Nastavit průhlednost',
        reset: 'Obnovit',
        resetToDefault: 'Obnovit výchozí'
      },
      shortcut: {
        shortcuts: 'Klávesové zkratky',
        close: 'Zavřít',
        textFormatting: 'Formátování textu',
        action: 'Akce',
        paragraphFormatting: 'Formátování odstavce',
        documentStyle: 'Styl dokumentu'
      },
      help: {
        'insertParagraph': 'Insert Paragraph',
        'undo': 'Undoes the last command',
        'redo': 'Redoes the last command',
        'tab': 'Tab',
        'untab': 'Untab',
        'bold': 'Set a bold style',
        'italic': 'Set a italic style',
        'underline': 'Set a underline style',
        'strikethrough': 'Set a strikethrough style',
        'removeFormat': 'Clean a style',
        'justifyLeft': 'Set left align',
        'justifyCenter': 'Set center align',
        'justifyRight': 'Set right align',
        'justifyFull': 'Set full align',
        'insertUnorderedList': 'Toggle unordered list',
        'insertOrderedList': 'Toggle ordered list',
        'outdent': 'Outdent on current paragraph',
        'indent': 'Indent on current paragraph',
        'formatPara': 'Change current block\'s format as a paragraph(P tag)',
        'formatH1': 'Change current block\'s format as H1',
        'formatH2': 'Change current block\'s format as H2',
        'formatH3': 'Change current block\'s format as H3',
        'formatH4': 'Change current block\'s format as H4',
        'formatH5': 'Change current block\'s format as H5',
        'formatH6': 'Change current block\'s format as H6',
        'insertHorizontalRule': 'Insert horizontal rule',
        'linkDialog.show': 'Show Link Dialog'
      },
      history: {
        undo: 'Krok vzad',
        redo: 'Krok vpřed'
      },
      specialChar: {
        specialChar: 'SPECIAL CHARACTERS',
        select: 'Select Special characters'
      }
    }
  });
})(jQuery);
